import React, {
    ChangeEvent, useMemo, useState,
  } from 'react';
  import TextField from '@mui/material/TextField';
  import MenuItem from '@mui/material/MenuItem';
  import _ from 'lodash';
  
  const OTHER_VALUE = '__OTHER__';
  
  interface IOption {
    value: string,
    label: string,
  }
  
  export interface IValue {
    hasOtherValue?: boolean;
    otherValue?: string;
    selectedValues?: string[];
  }
  
  interface Props {
    hidden?: boolean,
    value: IValue;
    disabled?: boolean;
    onChange: (newVal: IValue) => void;
    choices: IOption[];
    allowOther?: boolean;
    multiple?: boolean;
    emptyLabel?: string;
    label: string;
  }
  
  const OptionField: React.FC<Props> = ({
    value,
    disabled,
    onChange,
    choices,
    allowOther,
    multiple,
    emptyLabel,
    label,
    hidden = false,
  }: Props) => {
    const { hasOtherValue, otherValue, selectedValues } = _.cloneDeep(value);
    const [isOtherEnabled, setIsOtherEnabled] = useState(hasOtherValue);
    const [otherValueChoice, setOtherValueChoice] = useState(otherValue);
  
    const options = useMemo(() => {
      const optionElements = !emptyLabel
        ? [...choices]
        : [{ label: emptyLabel, value: '' }, ...choices];
      if (allowOther) {
        optionElements.push({
          label: 'Other',
          value: OTHER_VALUE,
        });
      }
      return optionElements;
    }, [choices.length]);
  
    const selected: string | string[] = useMemo(() => {
      const currentSelectedValues = selectedValues ?? []
      const otherSelected = isOtherEnabled ? [OTHER_VALUE] : [];
      const allSelectedValues = [...currentSelectedValues, ...otherSelected];
      return multiple ? allSelectedValues : allSelectedValues[0];
    }, [selectedValues]);
  
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onChange({
        hasOtherValue,
        otherValue,
        selectedValues: (multiple ? newValue : [newValue]) as string[] | undefined,
      });
      setIsOtherEnabled(newValue === OTHER_VALUE);
    };
  
    const handleOtherValueChange = (event: ChangeEvent<HTMLInputElement>) => {
      const otherValueChanged = event.target.value;
      onChange({
        hasOtherValue,
        otherValue: otherValueChanged,
        selectedValues,
      });
      setOtherValueChoice(otherValueChanged);
    };
  
    return (
      <>
        {!hidden && (
          <>
            <TextField
              key={`choice-${options.length}`}
              fullWidth
              select
              label={label}
              SelectProps={{
                multiple,
              }}
              disabled={disabled}
              value={selected || ''}
              onChange={handleChange}
            >
              {
                options.map((choice: IOption) => (
                  <MenuItem
                    key={choice.value + choice.label}
                    value={choice.value}
                  >
                    {choice.label}
                  </MenuItem>
                ))
              }
            </TextField>
            {
              isOtherEnabled
              && (
                <TextField
                  fullWidth
                  hiddenLabel
                  disabled={disabled}
                  value={otherValueChoice || ''}
                  onChange={handleOtherValueChange}
                  placeholder="Enter Other Value"
                />
              )
            }
          </>
        )}
      </>
    );
  };
  
  export default OptionField;
  