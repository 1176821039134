import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { Collapse, Icon, styled, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { iContactForm, iFormErrors } from 'standardTypes';
import CommonStyles from '../../utils/common-styling';
import { getError, ValidateForm } from '../shared/validate-form-field';
import {
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import AlertDialog from '../shared/alert-dialog';
import RecaptchaVerify from '../../utils/recaptcha-verify';
import ErrorMessages from '../../utils/errors';
import ErrorDialogSlide from '../shared/error-slide-dialog';

const emptyFormObject = {
    contactName: '',
    email: '',
    telefono: '',
    mensaje: '',
} as iContactForm;

interface iFormError {
    error?: string,
}

const ContactForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [formObject, setFormObject] = useState<iContactForm>(emptyFormObject as iContactForm)
    const [errors, setErrors] = useState<iFormErrors[]>([]);
    const [token, setToken] = useState<string | undefined>(undefined);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState({} as iFormError);
 
    const handleReCaptchaVerify = useCallback(async () => {
        console.log('handleReCaptchaVerify | init')
        if (!token) {
            const { errorObject, result, resultToken } = await RecaptchaVerify(executeRecaptcha);
            if (errorObject) { setFormErrors(errorObject) };
            if (token) { setToken(resultToken) };
            return result
        }
        console.log('Exiting, token is there already')
        console.log('Token is: ', token)
        return false

    }, [executeRecaptcha, token]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const { target: { value } } = event;
        setFormObject(
            {
                ...formObject,
                [field]: value
            }
        );
        setErrors(ValidateForm(formObject));
    }
    /* Validates full form only when user starts typing */
    useEffect(() => {
        const allEmptyFields = Object.keys(formObject).map((key) => formObject[key] === '')
            .every((result) => result === true);
        if (!allEmptyFields) {
            setErrors(ValidateForm(formObject));
        }
    }, [formObject])

    const sendEmailURL = 'https://us-central1-raycesmailer-6d5b2.cloudfunctions.net/sendEmail';

    const handleSubmit = async () => {
        console.log('handleSubmit | enviando email')
        const formErrors = ValidateForm(formObject);
        setErrors(formErrors);
        console.log('formErrors? ', formErrors)
        if (!!formErrors.length || Object.keys(formObject).length === 0) {
            console.log('Cannot sent. Check form')
        } else {
            console.log('Cheking recaptcha')
            const successToken = await handleReCaptchaVerify();
            if (successToken) {
                console.log('Token is: ', successToken)
                await Axios.get(sendEmailURL, {
                    params: {
                        message: formObject.mensaje,
                        name: formObject.contactName,
                        subject: 'Rayces.com: Nuevo mensaje desde Pagina Web',
                        email: formObject.email,
                        telephone: formObject.telefono,
                    }
                })
                    .then(() => {
                        console.log('formObject', formObject)
                        console.log('!!! Mensaje enviado')
                        setToken(undefined);
                        setFormObject(emptyFormObject);
                        setEmailSent(true);
                    })
                    .catch((error) => {
                        setFormErrors({ error: ErrorMessages.errorDeConexion })
                    })
            } else {
                // handle Error
                console.error('Errors in the form:', formErrors)
                setFormErrors({ error: ErrorMessages.errorEnFormulario })
            }
        }
    }

    return (
        <div id="contact-right">
            <h3>Contactenos</h3>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            />
            <div className="form-group">
                <InputTextField
                    id="outlined-basic"
                    autoComplete="off"
                    value={formObject.contactName}
                    label="Nombre"
                    inputProps={{ maxLength: 51 }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'contactName')}
                    required
                    error={getError(errors, 'contactName').isError}
                    helperText={getError(errors, 'contactName').textHelper}
                    variant="outlined"
                />
                <InputTextField
                    id="outlined-basic"
                    autoComplete="off"
                    value={formObject.email}
                    label="email"
                    inputProps={{ maxLength: 51 }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'email')}
                    required
                    error={getError(errors, 'email').isError}
                    helperText={getError(errors, 'email').textHelper}
                    variant="outlined"
                />
                <InputTextField
                    id="outlined-basic"
                    autoComplete="off"
                    value={formObject.telefono}
                    label="telefono"
                    inputProps={{ maxLength: 51 }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'telefono')}
                    required
                    error={getError(errors, 'telefono').isError}
                    helperText={getError(errors, 'telefono').textHelper}
                    variant="outlined"
                />
                <InputTextField
                    id="outlined-basic"
                    multiline
                    minRows={4}
                    maxRows={4}
                    autoComplete="off"
                    value={formObject.mensaje}
                    label="mensaje"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'mensaje')}
                    required
                    error={getError(errors, 'mensaje').isError}
                    helperText={getError(errors, 'mensaje').textHelper}
                    variant="outlined"
                />
                <Collapse in={!errors.length && Object.keys(formObject).length === 4}>
                    <div style={{ width: '100%', marginTop: '10px' }}>
                        <div className="boton-enviar" id="send-btn">
                            <div className="submit btn btn-lg btn-general btn-blue" onClick={handleSubmit}>
                                ENVIAR
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            {emailSent &&
                <AlertDialog doOpen={emailSent} doClose={() => setEmailSent(false)} title={'Rayces - Centro de Rehabilitacion e Inclusion Escolar'}>
                    Muchas gracias. Su mensaje ha sido enviado.
                </AlertDialog>
            }
            {!!formErrors.error &&
                <ErrorDialogSlide doOpen={!!formErrors.error} doClose={() => setFormErrors({error: undefined})} title={'Rayces - Centro de Rehabilitacion e Inclusion Escolar'}>
                    {formErrors.error}
                </ErrorDialogSlide>
            }
        </div>
    );
}

const InputTextField = styled(TextField)({
    marginTop: 16,
    width: '100%',
    "& .MuiInputBase-root": {
        color: 'white',
        fontSize: 16,
    },
    "& .MuiFormLabel-root": {
        color: CommonStyles.color.White,
        fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
        borderColor: CommonStyles.color.GreenishBlue,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: CommonStyles.color.GreenishBlue,
    },
    "& .MuiFormHelperText-root": {
        fontSize: 14,
    }
});

export default ContactForm;