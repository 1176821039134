import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ReportIcon from '@mui/icons-material/Report';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { iDialogProps } from 'standardTypes';
import { Box, Typography } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialogSlide: React.FC<iDialogProps> = ({ doOpen, doClose, children, title, isError }) => {
    const handleClose = () => {
        doClose();
    }

    return (
        <div>
            <Dialog
                open={doOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Typography sx={{ fontSize: 18 }}>
                    <Box sx={{ m: 2 }} display="flex" alignItems="center">
                        <Box
                            sx={{
                                '& > :not(style)': {
                                    mb: -1,
                                    mr: 2,
                                    color: isError ? 'red' : '#ed548a',
                                },
                            }}
                        >
                            { isError 
                             ? <ReportIcon sx={{ fontSize: 32 }} />
                             : <ThumbUpAltIcon sx={{ fontSize: 32}} />
                            }
                        </Box>
                        <Box sx={{ fontSize: 18 }}>
                            {title}
                        </Box>
                    </Box>
                </Typography>
                <DialogContent>
                    <DialogContentText sx={{ fontSize: 18 }} id="alert-dialog-slide-description">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ErrorDialogSlide;
