import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, styled } from '@mui/material';
import BasicDialog from '../../shared/basic-dialog';
import ImgPreinscripciones from '../../../img/events/event-2/event-2.jpeg'
import FormDrawerDialog from '../../shared/form-drawer-dialog';
import NewSignUpForm from '../../../system/system_forms/new-signup-form';
import AlertDialog from '../../shared/alert-dialog';
import ErrorDialogSlide from '../../shared/error-slide-dialog';
import ReSignUpForm from '../../../system/system_forms/re-signup-form';

type NewSignup = 'NewSignup';
type ReSignup = 'ReSignup';

type DrawerKey = NewSignup | ReSignup;

interface DrawerOptions {
    open: boolean,
    submitForm: boolean,
}

interface RootDrawers {
    NewSignup: DrawerOptions;
    ReSignup: DrawerOptions;
}

enum DrawerSelector {
    NewSignup = 'NewSignup',
    ReSignup = 'ReSignup'
}

const errorMessages = {
    'FAILED_ID_VALIDATION': "El DNI ingresado no corresponde a un alumno existente. Por favor revise el numero de DNI e intente nuevamente."
}

const StyledButton = styled(Button)({
    color: "#ed548a!important",
    width: "295px",
    padding: "10px 24px 10px 24px!important",
    fontSize: "14px!important",
    fontWeight: 700,
    '&:hover': {
        backgroundColor: "#ed548a!important",
        color: "#fff!important",
    },
    border: "2px solid #ed548a!important",
    backgroundColor: "transparent",
    borderRadius: "0!important",
  });

const EventPreinscripciones: React.FC = () => {
    const [openCta, setOpenCta] = useState(false);
    const [openHeroModal, setOpenHeroModal] = useState(true);
    const [ctaDisabled, setCtaDisabled] = useState(false);
    const [ctaFlowState, setCtaFlowState] = useState([]);
    const [progress, setProgress] = useState(0);
    const [openDrawer, setOpenDrawer] = useState<RootDrawers>({
        NewSignup: {
            open: false,
            submitForm: false,
        },
        ReSignup: {
            open: false,
            submitForm: false,
        },
    });

    const formFlowError = ctaFlowState[0] === 'ERROR';
    const formFlowSuccess = ctaFlowState[0] === 'SUCCESS';

    useEffect (() => {
        if (!openHeroModal) {
            setOpenCta(true);
        } else {
            setOpenCta(false);
        }
    }, [openHeroModal])

    const handleCloseDialog = () => {
        setOpenHeroModal(false);
    }

    const handleOpenDrawer = (drawer: DrawerKey, value: boolean) => {
        setOpenHeroModal(false);
        setOpenDrawer(
            {
                ...openDrawer,
                [drawer]: {
                    ...openDrawer[drawer],
                    open: value
                },
            }
        )
    }

    const handleSubmitDrawerForm = (drawer: DrawerKey, value: boolean) => {
        setOpenHeroModal(false);
        setOpenDrawer(
            {
                ...openDrawer,
                [drawer]: {
                    ...openDrawer[drawer],
                    submitForm: value
                },
            }
        )
    }

    const handleIamBusy = (state: boolean) => {
        setCtaDisabled(state);
    }

    const handleProgress = (progress: number) => {
        setProgress(progress);
    }

    const handleFormFlow = (response: []) => {
        console.log('HandleFormFlow | response', response);
        setCtaFlowState(response)
    };

    useEffect(()=>{
        console.log('OpenDrawer', openDrawer)
    }, [openDrawer])

    return (
        <div>
            <BasicDialog
                isOpen={openHeroModal}
                onCloseDialog={handleCloseDialog}
            >
                <Stack sx={{mt:2, mb: 2}} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <StyledButton onClick={() => handleOpenDrawer(DrawerSelector.NewSignup, true)}>
                        <Stack direction="column">
                            <span>Preinscripciones</span> 
                            <span>Nuevo Alumno</span>
                        </Stack>
                    </StyledButton>
                    <StyledButton onClick={() => handleOpenDrawer(DrawerSelector.ReSignup, true)}>
                        <Stack direction="column">
                            <span>Reinscripciones</span> 
                            <span>Alumno existente</span>
                        </Stack>
                    </StyledButton>
                </Stack>
                <Box>
                <img src={ImgPreinscripciones} className="event-img img-responsive" alt="Preinscripciones"/>
                </Box>
            </BasicDialog>
            <Box
                sx={{
                    zIndex: 100,
                    display: (openCta ? 'block' : 'none'),
                }}
                id="button-event2"
                className="btn btn-sm btn-event1 btn-blue"
                title="event"
                role="button"
                onClick={() => setOpenHeroModal(true)}

            >
                Abiertas las Inscripciones para Inclusion Escolar - SAIE
            </Box>
            {/* Form drawer */}
            <FormDrawerDialog 
                open={openDrawer.NewSignup.open}
                positive={ctaDisabled ? "Enviando..." : "Enviar"}
                negative="Cancelar"
                title={'Formulario Pre-Inscripciones Inclusión Escolar SAIE'} 
                positiveDisabled={ctaDisabled}
                negativeDisabled={ctaDisabled}
                onNegative={() => handleOpenDrawer(DrawerSelector.NewSignup, false)}
                onPositive={() => handleSubmitDrawerForm(DrawerSelector.NewSignup, true)}
                progress={progress}
            >
                <NewSignUpForm
                    submitForm={openDrawer.NewSignup.submitForm}
                    cleanSubmitFlag={() => handleSubmitDrawerForm(DrawerSelector.NewSignup, false)}
                    iAmBusy={(state: boolean) => handleIamBusy(state)}
                    handleFinishFlow={handleFormFlow}
                    progressCallBack={(progress: number) => setProgress(progress)}
                />
            </FormDrawerDialog>
            <FormDrawerDialog 
                open={openDrawer.ReSignup.open}
                positive={ctaDisabled ? "Enviando..." : "Enviar"}
                negative="Cancelar"
                title={'Formulario Re-Inscripciones Inclusión Escolar SAIE'} 
                positiveDisabled={ctaDisabled}
                negativeDisabled={ctaDisabled}
                onNegative={() => handleOpenDrawer(DrawerSelector.ReSignup, false)}
                onPositive={() => handleSubmitDrawerForm(DrawerSelector.ReSignup, true)}
                progress={progress}
            >
                <ReSignUpForm
                    submitForm={openDrawer.ReSignup.submitForm}
                    cleanSubmitFlag={() => handleSubmitDrawerForm(DrawerSelector.ReSignup, false)}
                    iAmBusy={(state: boolean) => handleIamBusy(state)}
                    handleFinishFlow={handleFormFlow}
                    progressCallBack={(progress: number) => setProgress(progress)}
                />
            </FormDrawerDialog>
            {formFlowSuccess &&
                <AlertDialog 
                    doOpen={formFlowSuccess}
                    doClose={() => {
                        setCtaFlowState([]); //cleans flow array
                        setProgress(0);
                        handleOpenDrawer(DrawerSelector.ReSignup, false)
                        handleOpenDrawer(DrawerSelector.NewSignup, false)
                    }} 
                    title={'Rayces - Centro de Rehabilitacion e Inclusion Escolar'}>
                    Muchas gracias. El formulario ha sido enviado. En breve recibira un correo electronico con un mensaje de confirmacion.
                </AlertDialog>
            }
            {formFlowError &&
                <ErrorDialogSlide
                    doOpen={formFlowError}
                    doClose={() => {
                        setCtaFlowState([]); //cleans flow array
                        setProgress(0);
                    }}
                    title={'Rayces - Centro de Rehabilitacion e Inclusion Escolar'}
                    isError={formFlowError}
                >
                    {errorMessages[ctaFlowState[2]] || 'Lo sentimos. Hubo un error enviando el formularion. Por favor intente nuevamente mas tarde.'}
                </ErrorDialogSlide>
            }
        </div>


    )
}

export default EventPreinscripciones;