const ProveedoresSalud = [
    {
        label: 'Aca Salud',
        value: 'Aca Salud',
    },
    {
        label: 'Ase Nacional',
        value: 'Ase Nacional',
    },
    {
        label: 'IOSFA',
        value: 'IOSFA',
    },
    {
        label: 'Luis Pasteur',
        value: 'Luis Pasteur',
    },
    {
        label: 'Medife',
        value: 'Medife',
    },
    {
        label: 'OSDE',
        value: 'OSDE',
    },
    {
        label: 'OSPE',
        value: 'OSPE',
    },
    {
        label: 'OSPECOM',
        value: 'OSPECOM',
    },
    {
        label: 'Poder Judicial',
        value: 'Poder Judicial',
    },
    {
        label: 'SANCOR',
        value: 'SANCOR',
    },
    {
        label: 'Swiss Medical',
        value: 'Swiss Medical',
    },
    {
        label: 'Particular',
        value: 'Particular',
    },
    {
        label: 'Otras',
        value: 'Otras',
    },
]
export default ProveedoresSalud;