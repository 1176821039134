const TutorSignupMessage = (tutor: string) => {
    /*
    HTML tag will need to be inlined and then minified. I used this tools until final solution found
    Original HTML inside rayces.com repo.

    -> Inliner: https://templates.mailchimp.com/resources/inline-css/
    -> Minifier : https://www.willpeavy.com/tools/minifier/
     */

    const htmlTag = `<!DOCTYPE html> <html> <head> <meta charset="utf-8"> <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1"> </head> <body style="padding: 15px;background-color: #FFF;margin: 0!important;">
    <div class="wrapper" style="width: 100%;table-layout: fixed;"> <div class="wrapper-inner" style="width: 100%;background-color: #eee;max-width: 670px;margin: 0 auto;">
    <table class="outer-table" style="border-spacing: 0;font-family: sans-serif;color: #727f80;width: 100%;max-width: 670px;margin: 0 auto;background-color: #FFF;">
    <tr> </tr></table> <table class="main-table-first" style="border-spacing: 0;font-family: sans-serif;color: #727f80;width: 100%;max-width: 610px;margin: 0 auto;background-color: #FFF;border-radius: 6px;margin-top: 25px;">
    <tr> </tr></table> <table class="outer-table" style="border-spacing: 0;font-family: sans-serif;color: #727f80;width: 100%;max-width: 670px;margin: 0 auto;background-color: #FFF;">
    <tr> <td class="image" style="padding: 0;"> </td><td class="image" style="padding: 0;"> <img src="http://www.rayces.com/img/mailing/rayces_banner.jpg" style="border: 0;width: 100%;max-width: 670px;height: auto;">
    </td></tr></table> <table class="main-table" style="border-spacing: 0;font-family: sans-serif;color: #727f80;width: 100%;max-width: 610px;margin: 0 auto;background-color: #FFF;border-radius: 6px;">
    <tr> <td class="one-column" style="padding: 0;"> <table width="100%" style="border-spacing: 0;font-family: sans-serif;color: #727f80;"> <tr>
    <td class="inner-td" style="padding: 10px;font-size: 16px;line-height: 20px;text-align: justify;">
    <p class="h9" style="margin: 0px;text-align: left;font-size: 23px;font-weight: 600;line-height: 45px;margin-bottom: 12px;color: #4A4A4A;">$%nombreTutor</p>
    <p class="justified" style="margin: 0;"> Muchas gracias por comunicarte con Rayces.<br>Este email es para confirmarte que hemos recibido los datos de tu solicitud de preinscripción.</p>
    <p style="margin: 0;">Nos pondremos en contacto a la brevedad para definir una primera cita en relación a tu solicitud.</p>
    <p class="" style="margin: 0;"><br>Muchas gracias por tu confianza. <br></p></td></tr><tr style="text-align: right;">
    <td style="padding: 0;"> <p class="h10" style="margin: 0px;text-align: right;font-size: 18px;font-weight: 600;line-height: 45px;margin-bottom: 5px;margin-right: 5px;color: #4A4A4A;">El equipo de Rayces</p>
    <p class="h12" style="margin: 0px;text-align: right;font-size: 14px;font-weight: 600;line-height: 18px;padding: 0;margin-right: 5px;color: #BF1069;">
    <a class="h12" href="http://www.rayces.com" target="_blank" style="color: #BF1069;text-decoration: none;text-align: right;font-size: 14px;font-weight: 600;line-height: 18px;margin: 0px;padding: 0;margin-right: 5px;">www.rayces.com</a></p>
    <p class="h11" style="margin: 0px;text-align: right;font-size: 14px;font-weight: 600;line-height: 18px;padding: 0;margin-right: 5px;color: #4A4A4A;">12 de Octubre 832, 4400 Salta, Argentina</p>
    <p class="h11" style="margin: 0px;text-align: right;font-size: 14px;font-weight: 600;line-height: 18px;padding: 0;margin-right: 5px;color: #4A4A4A;">Teléfono: (54387) 422-9166</p></td></tr></table> </td></tr></table>
    <table class="outer-table-3" style="border-spacing: 0;font-family: sans-serif;color: #727f80;width: 100%;max-width: 670px;margin: 22px auto;background-color: #C2C1C1;border-top: 3px solid #81B9C3;">
    <tr> <td class="one-column" style="padding: 0;"> <table width="100%" style="border-spacing: 0;font-family: sans-serif;color: #727f80;">
    <table class="outer-table" style="border-spacing: 0;font-family: sans-serif;color: #727f80;width: 100%;max-width: 670px;margin: 0 auto;background-color: #FFF;">
    <tr> <td class="image" style="padding: 0;"> </td><td class="image" style="padding: 0;">
    <img src="http://www.rayces.com/img/mailing/rayces_banner_bottom.jpg" style="border: 0;width: 100%;max-width: 670px;height: auto;">
    </td></tr></table> </table> </td></tr></table> </div></div><br></body>
    </html>`;

return {
    subject: 'Rayces.com - Solicitud de Pre-inscripcion recibida',
    htmlTag: htmlTag.replace('$%nombreTutor', tutor)
    };
}

export default TutorSignupMessage;