import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Icon, Typography } from '@mui/material';
import { iDialogProps } from 'standardTypes';
import { green } from '@mui/material/colors';
import { CheckBox } from '@mui/icons-material';
import CommonStyles from '../../utils/common-styling';

const AlertDialog: React.FC<iDialogProps> = ({ doOpen, doClose, children, title }) => {
  const handleClose = () => {
    doClose();
  }

  return (
      <Dialog
        open={doOpen}
        onClose={doClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontSize: 18 }}>
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  '& > :not(style)': {
                    mb: -1,
                    mr: 2,
                    color: CommonStyles.color.GreenishBlue,
                  },
                }}
              >
               <CheckBox sx={{ fontSize: 32 }} />
              </Box>
              <Box sx={{fontSize: 18}}>
                {title}
              </Box>
            </Box>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ fontSize: 18 }}>{children}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default AlertDialog;