/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Stack } from '@mui/material';
import LinearProgressWithLabel from '../../utils/linear-progress-with-label';


type ButtonClose = React.MouseEventHandler<HTMLButtonElement>;
type DialogClose = ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void)
interface TitleProps {
  children: React.ReactNode;
  onClose: DialogClose | ButtonClose | undefined;
}

type OmitProps = 'onSubmit' | 'sx' | 'title';
interface Props extends Omit<DialogProps, OmitProps> {
  open: boolean;
  title: string;
  serverError?: boolean;
  positive?: string;
  negative?: string;
  disabled?: boolean;
  positiveDisabled?: boolean;
  negativeDisabled?: boolean;
  onPositive?: () => void;
  onNegative: (e: null | React.SyntheticEvent) => void;
  onSubmit?: (event: React.SyntheticEvent<HTMLFormElement>) => void;
  progress?: number;
}

const CurstomDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#F9F9F9!important',
    },
  '& .MuiDialogContent-root': {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    color: '#ed548a',
    fontSize: '2rem',
    letterSpacing: '0.0075em',
    backgroundColor: 'rgba(249,249,249,0.95)!important',
    paddingBottom: '10px',
    width: 'auto',
    margin: '2px auto',
  },
  '& .MuiDrawer-paper': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
    boxSizing: 'border-box',
  },
  '& .MuiTabs-flexContainer': {
    padding: '0 32px',
  },
  '& .MuiTab-root': {
    width: '50%',
  },
}));

const CurstomDrawerPositiveButton = styled(MuiButton)(({ theme }) => ({
    color: "#ed548a!important",
    padding: "10px 24px!important",
    fontSize: "14px!important",
    fontWeight: 700,
    '&:hover': {
        backgroundColor: "#ed548a!important",
        color: "#fff!important",
    },
    border: "2px solid #ed548a!important",
    backgroundColor: "transparent",
    borderRadius: "0!important",
  '&:disabled': {
    color: '#FFF!important',
    backgroundColor: 'gray!important',
    border: "2px solid gray!important",
  },
}));

const CurstomDrawerNegativeButton = styled(MuiButton)(({ theme }) => ({
    color: "#ed548a!important",
    padding: "10px 24px!important",
    fontSize: "14px!important",
    fontWeight: 700,
    '&:hover': {
        backgroundColor: "#ed548a!important",
        color: "#fff!important",
    },
    border: "2px solid #ed548a!important",
    backgroundColor: "transparent",
    borderRadius: "0!important",
  '&:disabled': {
    color: '#FFF!important',
    backgroundColor: 'gray!important',
    border: "2px solid gray!important",
  },
}));

const CurstomDrawerContent = styled(MuiDialogContent)({
  display: 'flex',
  flexDirection: 'column',
});

const CurstomDrawerTitle = (props: TitleProps) => {
  const { children, onClose } = props;

  return (
    <DialogTitle>
        {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose as ButtonClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function FormDrawerDialog({
  open,
  onNegative,
  onPositive,
  disabled,
  positiveDisabled,
  negativeDisabled,
  serverError,
  onSubmit,
  title,
  progress = 0,
  ...props
}: Props) {
  return (
    <CurstomDrawer
      data-testid="drawer"
      {...props}
      anchor="right"
      onClose={props.onClose}
      open={open}
    >
      <CurstomDrawerTitle data-testid="dialog-title" onClose={props.onClose}>
        {title}
      </CurstomDrawerTitle>
      <CurstomDrawerContent dividers>
        <form noValidate onSubmit={onSubmit} id="dialog-form">
          {props.children}
        </form>
      </CurstomDrawerContent>
      <DialogActions sx={{width: '100%'}}>
        <Stack sx={{width: '100%'}} direction="column" justifyContent={"space-between"} >
          {progress > 0 && <LinearProgressWithLabel sx={{width: '100%'}} value={ progress } />}
          <Stack direction="row" justifyContent="end" spacing={1} >
            <CurstomDrawerNegativeButton
              disabled={negativeDisabled || disabled}
              onClick={onNegative}
            >
              {props.negative ?? 'Cancel'}
            </CurstomDrawerNegativeButton>
            <CurstomDrawerPositiveButton
              form={onSubmit ? 'dialog-form' : undefined}
              type={onSubmit ? 'submit' : 'button'}
              disabled={positiveDisabled || disabled}
              onClick={onPositive}
              data-button-id="task-submit-button"
            >
              {serverError ? 'Resubmit' : props.positive ?? 'Submit'}
            </CurstomDrawerPositiveButton>
          </Stack>
        </Stack>
      </DialogActions>
    </CurstomDrawer>
  );
}
