import { createTheme } from "@mui/material";

const AppMuiTheme = createTheme({
    palette: {
      primary: {
        main: '#ed548a',
      },
    },
    typography: {
      fontFamily: "'Roboto Condensed', 'san-serif'",
      fontSize: 24,
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#ed548a",
            "&$error": {
              color: "#ed548a",
            },
          },
        },
      },
    },
  });

export default AppMuiTheme;