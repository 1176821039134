import React, {
    ChangeEvent, FocusEvent, useCallback, useEffect, useState,
  } from 'react';
  import MUITextField from '@mui/material/TextField';
  
  interface Props {
    hidden?: boolean,
    disabled?: boolean,
    value: { textValue: string },
    onChange: (value: any) => void,
    label: string,
    required: boolean,
    onBlur(event: FocusEvent<HTMLInputElement>): void,
    onFocus(event: FocusEvent<HTMLInputElement>): void,
    withError?: boolean,
    helperText?: string,
    errorMessage: string,
  }
  
  const TextField: React.FC<Props> = ({
    hidden = false,
    disabled,
    value,
    onChange,
    label,
    required,
    withError,
    helperText,
    errorMessage,
  }: Props) => {
    const [isBlur, setIsBlur] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [textValue, setTextValue] = useState<string>(value.textValue || '');
    const [textHelper, setTextHelper] = useState<string|undefined>(helperText);
  
    const handleError = useCallback(() => (
      required && (isEmpty || !!errorMessage ) && (withError || isBlur)
    ), [errorMessage, isBlur, isEmpty, required, withError]);
  
    useEffect(() => {
      setTextHelper(handleError() ? errorMessage : helperText);
    }, [errorMessage, handleError, helperText]);
  
    const handleFocus = () => {
      setIsBlur(false);
    };
  
    const handleBlur = () => {
      setIsBlur(true);
  
      setIsEmpty(false);
      textValue === '' ? setIsEmpty(true) : setIsEmpty(false);
    };
  
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      const inputValue = event.target.value;
  
      setTextValue(inputValue);
      setIsEmpty(false);
  
      onChange({ textValue: inputValue });
    };
  
    return (
      <>
        {!hidden
          && (
            <MUITextField
                variant="outlined"
                multiline
                value={textValue}
                onChange={handleChange}
                label={label}
                disabled={disabled}
                required={required}
                fullWidth
                error={handleError()}
                helperText={textHelper}
                onBlur={handleBlur}
                onFocus={handleFocus}
                hidden={hidden}
                InputProps={{ style: { fontSize: 16 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
            />
          )}
      </>
    );
  };
  
  export default TextField;
  