export interface ReSignUpRecord {
    formId: string,
    formValues: Record<string, any>,
}

const reSignUpRecord = {
    formId: 're-signup-form',
    formValues: {
    }
}
//FOr testing only //
/* const reSignUpRecord = {
    "formId": "re-signup-form",
    "formValues": {
        "rs345": "48414338",
        "rs228": {
            "choice_values": [
                "2023"
            ]
        },
        "rs335": "Almirante",
        "rs220": "canriquez@gmail.com",
        "rs889": "01199299988",
        "rs777": "checked"
    }
} */

export default reSignUpRecord;