

import * as React from 'react';
import { iSocialObject, iTeamMember, iTeamMemberObject } from 'standardTypes'

const SocialLink: React.FC<iSocialObject> = (socialItem) => {
    return (
        <ul className="social-list">
            <li>
                <a href={socialItem.url} className="social-icon icon-geay">
                    <i className={socialItem.socialClass} />
                </a>
            </li>
        </ul>
    )
}

const TeamMemberCard: React.FC<iTeamMemberObject> = ({ member, professionalDetails, noDescription = false }) => {

    const openProfessionalModal = (member: iTeamMember) => {
        if (professionalDetails) {
            professionalDetails(member);
        }
    }
    
    return (
        <div className="team-member">
            <img src={member.image} className="img-responsive" alt="team member" onClick={() => openProfessionalModal(member)} />
            <div className="team-member-info text-center">
                {!noDescription && member.memberName && <h4 className="team-member-name">{member.memberName}</h4>}
                {!noDescription && member.memberTitle && <h4 className="team-member-designation">{member.memberTitle}</h4>}
                {!noDescription && member.memberRole && <h4 className="team-member-role">{member.memberRole}</h4>}

                {
                    member.socialList && !noDescription
                        ? (
                            member.socialList.map((socialLinkItem) => (
                                <SocialLink url={socialLinkItem.url} socialClass={socialLinkItem.socialClass} />
                            ))
                        )
                        : ''
                }

            </div>
        </div>
    )
}

export default TeamMemberCard;
