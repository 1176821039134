import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Stack } from '@mui/material';

interface Props {
    label: string;
    value: { textValue: boolean };
    hidden: boolean;
    disabled: boolean;
    onChange(value: any): void,
    children: Element,
}

const CheckBoxField: React.FC<Props> = ({label, value, hidden, disabled, onChange, children}) => {
  const [checked, setChecked] = React.useState(value.textValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    if (checked) {
        onChange({ textValue: 'checked' })
    } else {
        onChange({ textValue: 'unset' })
    }
  }, [checked])

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Checkbox
        checked={checked}
        onChange={handleChange}
        required
        inputProps={{ 'aria-label': 'controlled' }}
        />
        {children}
    </Stack>

  );
}

export default CheckBoxField;