/* DEFAUL VALUES:
                    
Font Family:                :Roboto Condensed, san-serif if roboto no available.
    
Greenish Blue               : #ed548a   (buttons, Icons, Links, Lines &backgrounds)
Steel Gray                  : #41464b   (headings)
Blue Bayoux                 : #363b40   (Paragraphs)
White                       : #fff      (Text With Black Backgrounds)
Black                       : #000
 */
const CommonStyles = {
    color: {
        'GreenishBlue': '#ed548a',
        'SteelGray': '#41464b',
        'BlueBayoux': '#363b40',
        'White': '#FFF',
        'Black': '#000',
    }
}

export default CommonStyles;