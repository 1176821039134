import * as React from 'react';

const Services = () => (
/*     <!-- Services --> */
    <section id="services">
        <div className="content-box">
            <div className="content-title wow animated fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
                <h3> Nuestros Servicios </h3>
                <div className="content-title-underline"></div>
            </div>

            <div className="container">
                <div className="row">
{/*                     <!-- maximum coloums in BS is 12, so after 3 coloums of 4, BS automatically adds a new row!! -->
                    <!-- Still if coloumns have different sizes, there could be issues on row overflows. in my 
                case there was an error, and I needed to re adjust the columns to try to avoid. Bs documentation
                explains that you could do a responsive reset : http://blog.codeply.com/2016/04/06/how-the-bootstrap-grid-really-works/, but I was not able to fix it this way here is the specific documention :http://getbootstrap.com/css/#grid-responsive-resets.
                In my case I've used <br> spaces to adjust the columns, this is not the perfect solution, but It would do until I adjust more urgente matters -->

                    <!-- start new refresh page - adding 7th service type --> */}
                    <div className="col-md-12 col-sm-12">

                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-graduation-cap fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Inclusión Escolar </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Acompañamiento al niño en proceso de inclusión con nuestras maestras de apoyo dentro de la escuela o en sede. Configuraciones de apoyo, identificación de barreras para el aprendizaje y modificación del entorno.</li>
                                    <li className="list-services-item">Promoción de la participación plena en el marco de la vida escolar del niño. Construcción de Estrategias inclusivas junto a la escuela y la familia. Recursos y herramientas para docentes de aulas heterogéneas. </li>
                                    <li className="list-services-item">Orientación a la familia. Actividades de capacitación dirigidas a trabajar de manera consciente la educación para la diversidad. </li>
                                </ul>

                            </div>

                        </div>
                    </div>
{/*                     <!-- end first row with Inclusion escolar -->
 */}
                    <div className="col-md-4 col-sm-6">

                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-universal-access fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Psicomotricidad </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Atención temprana del desarrollo.</li>
                                    <li className="list-services-item">Generamos un espacio psicomotriz, que amplia la posibilidad que tiene el niño de accionar con su cuerpo, en función de sus necesidades, intereses y de las demandas del medio. </li>
                                    <li className="list-services-item">Psicomotricidad educativa y  terapéutica.</li>
                                </ul>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-smile-o fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Fonoaudiología </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Evaluación Neurolingüística y terapia del lenguaje con enfoque neuroligüistico en niños y adolescentes.</li>
                                    <li className="list-services-item">Comunicación bimodal. Intervencion y Estimulacion Temprana.</li>
                                    <li className="list-services-item">Otoemisiones Acusticas. Prompt.</li>
                                </ul>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-sign-language fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Servicio Social </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Intervención con las familias para el conocimiento de los procesos sociales.</li>
                                    <li className="list-services-item">Participación en las intervenciones de los sujetos y en el desarrollo social.</li>
                                    <li className="list-services-item">Acciones necesarias para restablecer o conservar la autodeterminación y funcionamiento tanto individual como colectivo.</li>
                                </ul>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-pencil-square-o fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Psicopedagogía </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Evaluación neuropsicológica y tratamiento psicopedagógico.</li>
                                    <li className="list-services-item">Acompañamiento de la trayectoria Escolar.</li>
                                    <li className="list-services-item">Orientación a padres y colegios. <br/><br/><br/></li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-puzzle-piece fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Psicología </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Psicodiagnóstico y Psicoterapia Clínica para niños, adolescentes y adultos.</li>
                                    <li className="list-services-item">Terapia cognitiva.</li>
                                    <li className="list-services-item">Orientación Vocacional.</li>
                                </ul>

                            </div>

                        </div>

                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="service-item wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">

                            <div className="service-item-icon">

                                <i className="fa fa-music fa-3x"></i>
                            </div>
                            <div className="service-item-title">
                                <h3>Musicoterapia </h3>
                            </div>
                            <div className="service-item-description">
                                <ul className="list-services">
                                    <li className="list-services-item">Musicoterapia Clínica.</li>
                                    <li className="list-services-item">Niños y adolescentes.</li>
                                    <li className="list-services-item">Abordaje individual y grupal.</li>
                                </ul>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>

    </section>
)

export default Services;