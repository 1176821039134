
import * as React from 'react';
import Logo from '../img/logo.png'
import {ReactComponent as ReactLogo} from '../img/Logo.svg'

const Header = () => (
    <header>
    <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">

            <div className="rayces-nav-wrapper">

                <div className="navbar-header">

                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#rayces-menu">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="#home">
{/*                         <img src={ReactLogo} alt="logl"/> */}
                        <ReactLogo className="brand-logo" />
                    </a>
                </div>
                <div className="collapse navbar-collapse" id="rayces-menu">
                    <ul className="nav navbar-nav">
                        <li><a className="smooth-scroll" href="#home">Inicio</a></li>
                        <li><a className="smooth-scroll" href="#services">Servicios</a></li>
                        <li><a className="smooth-scroll" href="#about">Rayces</a></li>
                        <li><a className="smooth-scroll" href="#work">Pasión</a></li>
                        <li><a className="smooth-scroll" href="#team">Equipo</a></li>
                        <li><a className="smooth-scroll" href="#testimonials">Testimonio</a></li>
                        <li><a className="smooth-scroll" href="#contact">Contacto</a></li>
{/*                         <li><a href="https://blog.rayces.com">Publicaciones</a></li> */}
{/*                         <!-- <li><a className="smooth-scroll" href="#stats">Stats</a></li> -->
                        <!-- <li><a className="smooth-scroll" href="#clients">Prestadores</a></li> --> */}
{/*                         <!-- <li><a className="smooth-scroll" href="#login">Login</a></li> --> */}
                    </ul>
                </div>
            </div>
        </div>

    </nav>
</header>
)

export default Header;