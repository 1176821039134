import Logo1 from '../../../img/client/logos/logo-1.jpg'
import Logo2 from '../../../img/client/logos/logo-2.jpg'
import Logo3 from '../../../img/client/logos/logo-3.jpg'
import Logo4 from '../../../img/client/logos/logo-4.jpg'
import Logo5 from '../../../img/client/logos/logo-5.jpg'
import Logo6 from '../../../img/client/logos/logo-6.jpg'
import Logo7 from '../../../img/client/logos/logo-7.jpg'
import Logo8 from '../../../img/client/logos/logo-8.jpg'
import Logo9 from '../../../img/client/logos/logo-9.jpg'


const ServiceProviders = [
        {
            logo: Logo1,
            name: 'Osde',
            enabled: true,
        },
        {
            logo: Logo2,
            name: 'SwissMedical',
            enabled: true,
        },
        {
            logo: Logo3,
            name: 'Sancor',
            enabled: true,
        },
        {
            logo: Logo4,
            name: 'Iose',
            enabled: true,
        },
        {
            logo: Logo5,
            name: 'Oscep',
            enabled: true,
        },
        {
            logo: Logo6,
            name: 'Osfatlyf',
            enabled: true,
        },
        {
            logo: Logo7,
            name: 'ConstruirSalud',
            enabled: true,
        },
        {
            logo: Logo8,
            name: 'IPS',
            enabled: false,
        },
        {
            logo: Logo9,
            name: 'OSPE',
            enabled: true,
        },

    ]
export default ServiceProviders;