import * as React from 'react';
import { iTestimonial } from 'standardTypes';
import InformationDialog from '../../shared/information-dialog';
import TestimonialCard from '../../shared/testimonial-card';
import TestimonialAssets from './testimonial-assets'


const Testimonials: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false)
    const [selectedTestimonial, setSelectedTestimonial] = React.useState({} as iTestimonial)
    const handleOpenTestimonialDetails = (testimonial: iTestimonial) => {
        setModalOpen(true)
        setSelectedTestimonial(testimonial)
    }
    return (
        <section id="testimonials">
            <div id="testimonals-cover" className="bg-parallax">
                <div className="content-box">
                    <div className="content-title  content-title-white wow animated fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
                        <h3> Nuestas familias comparten su experiencia </h3>
                        <div className="content-title-underline"></div>
                    </div>
                    <div className="container">
                        <div className="row wow animated bounceInDown" data-wow-duration="1s" data-wow-delay=".5s">
                            <div className="col-md-12">
                                <div id="customers-testimonials" className="text-center owl-carousel owl-theme">
                                    { TestimonialAssets.map((testimonial: iTestimonial) => 
                                        <TestimonialCard key={testimonial.image} testimonial={testimonial} testimonialWords={handleOpenTestimonialDetails} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedTestimonial.wordsTitle && selectedTestimonial.words &&
                <InformationDialog doOpen={modalOpen} doClose={() => setModalOpen(false)} title={selectedTestimonial.wordsTitle}>
                    <div className="container-fluid">
                        <div className="col-md-3">
                            <img src={selectedTestimonial.image} className="img-responsive" alt="team member" />
                        </div>
                        <div className="col-md-9">
                            <p className="palabras">{selectedTestimonial.words}</p>
                        </div>
                    </div>
                </InformationDialog>
            }
        </section>
    )
}

export default Testimonials;