import PrivacyPolicyHandler from "../../../utils/privacy-policy-handler";
import ProveedoresSalud from "./proveedores-salud"
import { PUPIL_FORM_KEY } from "./pupil-field-config";

const PupilFields = [
    {
        key: PUPIL_FORM_KEY.nombreCompleto,
        label: 'Nombre completo',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'min'
        },
        disabled: false,
        description: 'Introduzca su numbre completo.',
    },
    {
        key: PUPIL_FORM_KEY.documentoId,
        label: 'DNI',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'dni'
        },
        disabled: false,
        description: 'Documento Nacional de Identidad.',
    },
    {
        key: PUPIL_FORM_KEY.fechaNacimiento,
        label: 'Fecha de nacimiento',
        type: 'DateField',
        required: true,
        hidden: false,
        validation: {},
        disabled: false,
        description: '',
    },
    {
        key: PUPIL_FORM_KEY.diagnostico,
        label: 'Diagnostico',
        type: 'TextField',
        required: true,
        multipleLines: true,
        hidden: false,
        validation: {
            state: true,
            type: 'min'
        },
        disabled: false,
        description: 'Breve resumen del diagnostico',
    },
    {
        key: PUPIL_FORM_KEY.escolarizado,
        label: 'Escolarizado',
        type: 'OptionField',
        required: true,
        multipleLines: true,
        hidden: false,
        disabled: false,
        description: '',
        choice_values: [
            {
                label: 'Si',
                value: 'si',
            },
            {
                label: 'No',
                value: 'no'
            }
        ]
    },
    {
        key: PUPIL_FORM_KEY.escuela,
        label: 'Escuela',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'min'
        },
        disabled: false,
        description: 'Nombre de establecimiento educativo al que asiste el alumno.',
    },
    {
        key: PUPIL_FORM_KEY.nombreCompletoTutor,
        label: 'Nombre del Tutor',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'min'
        },
        disabled: false,
        description: 'Introduzca su numbre completo.',
    },
    {
        key: PUPIL_FORM_KEY.emailTutor,
        label: 'Email del tutor',
        type: 'TextField',
        required: true,
        validation: {
            state: true,
            type: 'email'
        },
        hidden: false,
        disabled: false,
        description: 'email del tutor.',
    },
    {
        key: PUPIL_FORM_KEY.telTutor,
        label: 'Telefono',
        type: 'TextField',
        required: true,
        hidden: false,
        description: '',
        validation: {
            state: true,
            type: 'telephone'
        },
    },
    {
        key: PUPIL_FORM_KEY.dniTutor,
        label: 'DNI del Tutor',
        type: 'TextField',
        required: true,
        hidden: false,
        validation: {
            state: true,
            type: 'dni'
        },
        disabled: false,
        description: 'Tutor: Documento Nacional de Identidad.',
    },
    {
        key: PUPIL_FORM_KEY.coberturaMedica,
        label: 'Seleccione Cobertura de Salud',
        type: 'OptionField',
        required: true,
        multipleLines: true,
        default: 'Sin cobertura',
        hidden: false,
        disabled: false,
        description: 'Seleccione Cobertura de Salud',
        choice_values: ProveedoresSalud,
    },
    {
        key: PUPIL_FORM_KEY.tieneCud,
        label: 'Tiene certificado unico de discapacidad (CUD)?',
        type: 'OptionField',
        required: true,
        multipleLines: true,
        hidden: false,
        disabled: false,
        description: '',
        default: 'No',
        choice_values: [
            {
                label: 'Si',
                value: 'si',
            },
            {
                label: 'No',
                value: 'no'
            }
        ]
    },
    {
        key: PUPIL_FORM_KEY.referencia,
        label: 'Como llego a Rayces?',
        type: 'TextField',
        required: false,
        hidden: false,
        disabled: false,
        description: '',
    },
    {
        key: PUPIL_FORM_KEY.okPrivacy,
        label: 'Privacidad',
        type: 'CheckField',
        required: true,
        hidden: false,
        disabled: false,
        description: () => <PrivacyPolicyHandler />
    },
];

export default PupilFields;