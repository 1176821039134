import Axios from "axios";
import moment from "moment";
import BuildMailTag, { EmailTagType } from "../../system/mailing/build-mail-tag";

interface ISpreadSheetSubmitHandler {
    record: Record<string, any>;
    formConfig: {key:string}[];
    formKeys: Record<string, any>;
    spreadsheetId: string;
    range: string;
    idRange: string;
    progressCallBack: (progress: number) => void;
    tutorEmailTagType: string,
    internalEmailTagType: string,
    validateRangeId?: string,
    validateRange?: string,
}

const buildRowArray = (formConfig: {key:string}[], formKey: Record<string, any>, record: Record<string, any>) => {
    switch (formKey.formId) {
        case 'signup-form': {
            const row = formConfig.map((field: Record<string, any>) => {
                const choiseResult = !!record.formValues[field.key].choice_values
                if (choiseResult) { 
                    return  record.formValues[field.key].choice_values[0];
                } else {
                    if (field.key === formKey.fechaNacimiento) {
                        return String(moment(new Date(record.formValues[field.key])).format("DD/MM/YYYY"));
                    } else {
                        return record.formValues[field.key];
                    }
                }
            });
            // adding Date
            const now = moment().format("DD/MM/YY HH:mm:ss") as string;
            row.unshift(now);
            // adding ID
            row.unshift('Dummy Id');
            // Injects Age Calculation
            const years = moment().diff(moment(new Date(record.formValues[formKey.fechaNacimiento])), 'years');
            row.splice(5,0,years);
            return row;
            };
            case 're-signup-form': {
                const row = formConfig.map((field: Record<string, any>) => {
                    const choiseResult = !!record.formValues[field.key].choice_values
                    if (choiseResult) { 
                        return  record.formValues[field.key].choice_values[0];
                    } else {
                        if (field.key === formKey.fechaNacimiento) {
                            return String(moment(new Date(record.formValues[field.key])).format("DD/MM/YYYY"));
                        } else {
                            return record.formValues[field.key];
                        }
                    }
                });
                // adding Date
                const now = moment().format("DD/MM/YY HH:mm:ss") as string;
                row.unshift(now);
                // adding ID
                row.unshift('Dummy Id');
                return row;
            };
        default:
            return [];
    }

}

const SubmitSpreadsheetHandler = ({
    record,
    formConfig,
    formKeys,
    spreadsheetId,
    range,
    idRange,
    progressCallBack,
    validateRange = undefined,
    validateRangeId,
    tutorEmailTagType,
    internalEmailTagType,
}: ISpreadSheetSubmitHandler) => {

    const customPromise = new Promise(async (resolve, reject) => {

        const rowArray = buildRowArray(formConfig, formKeys, record);
        const updateWorkSheetlURL = 'https://us-central1-raycesmailer-6d5b2.cloudfunctions.net/updateWorkSheet';
        /* Updates spreadsheet */
        let formSubmissionError = false;
        let updateResponse: string[] = [];
        await Axios.get(updateWorkSheetlURL, {
            headers: {
                "Authorization" : `Bearer ${process.env.REACT_APP_UPDATE_SIGNUP_WORSHEET_KEY}`
            },
            params: {
                spreadsheetId,
                range,
                idRange,
                valueInputOption: 'RAW',
                values: rowArray,
                validateRange,
                validateRangeId,
            }
        })
            .then((response: { data: { info: any; }; }) => {
                const { info } = response.data;
                console.log('!!! Update enviado - response:', response.data.info)
                console.log('Info:', info)
                updateResponse = [...response.data.info];
                if (info === 'FAILED_ID_VALIDATION') {
                    reject(['ERROR','Update Spreadsheet Failure',info])
                    formSubmissionError = true;
                }
            })
            .catch((error) => {
                console.error('Failure sending spreadsheet update', error)
                reject(['ERROR','Update Spreadsheet Failure',error]);
                formSubmissionError = true;
            })

        if (!formSubmissionError) {
            progressCallBack(33);
            /* sending TUTOR confirmation emails */
            const sendConfirmationEmailsURL = `https://us-central1-raycesmailer-6d5b2.cloudfunctions.net/sendConfirmationEmails`;
            await Axios.get(sendConfirmationEmailsURL, {
                headers: {
                    "Authorization" : `Bearer ${process.env.REACT_APP_UPDATE_SIGNUP_WORSHEET_KEY}`
                },
                params: BuildMailTag(tutorEmailTagType, rowArray, updateResponse[3] as unknown as string[]),
                // for the re-signup case, updateResponse[3] has all the new re-signup row data from backend.
            })
                .then((response: { data: { info: any; }; }) => {
                    console.log('Tutor confirmation sent:', response.data.info);
                })
                .catch((error) => {
                    console.error('Failed email delivery:', error.data.info)
                    reject(['ERROR','Tutor confirmation email failure',error]);
                })
            progressCallBack(66);
            /* sending INTERNAL confirmation emails */
            await Axios.get(sendConfirmationEmailsURL, {
                headers: {
                    "Authorization" : `Bearer ${process.env.REACT_APP_UPDATE_SIGNUP_WORSHEET_KEY}`
                },
                params: BuildMailTag(internalEmailTagType, rowArray, updateResponse[3] as unknown as string[]),
                // for the re-signup case, updateResponse[3] has all the new re-signup row data from backend.
            })
                .then((response: { data: { info: any; }; }) => {
                    console.log('Internal confirmation sent:', response.data.info)
                    progressCallBack(100);
                    resolve(['SUCCESS','Signup successfull flow', response.data.info]);
                })
                .catch((error) => {
                    //setFormErrors({ error: ErrorMessages.errorDeConexion })
                    console.error('Failed email delivery:', error.data.info)
                    reject(['ERROR','Internal confirmation email failure',error]);
                })
        }
    });
    return customPromise;
}

export default SubmitSpreadsheetHandler;