
export const PUPIL_FORM_KEY = {
    formId: 'signup-form',
    nombreCompleto: 'a234',
    documentoId: 'b345',
    fechaNacimiento: 'c567',
    diagnostico: 'd789',
    escolarizado: 'e123',
    escuela: 'f789',
    nombreCompletoTutor: 't335',
    emailTutor: 'm220',
    telTutor: 't889',
    dniTutor: 'd776',
    coberturaMedica: 'x889',
    tieneCud: 'c098',
    referencia: 'r898',
    okPrivacy: 'p777',
}

const pupilFieldConfig = [
    {
        key: PUPIL_FORM_KEY.nombreCompleto,
    },
    {
        key: PUPIL_FORM_KEY.documentoId,
    },
    {
        key: PUPIL_FORM_KEY.fechaNacimiento,
    },
    {
        key: PUPIL_FORM_KEY.diagnostico,
    },
    {
        key: PUPIL_FORM_KEY.escolarizado,
    },
    {
        key: PUPIL_FORM_KEY.escuela,
    },
    {
        key: PUPIL_FORM_KEY.coberturaMedica,
    },
    {
        key: PUPIL_FORM_KEY.tieneCud,
    },
    {
        key: PUPIL_FORM_KEY.nombreCompletoTutor,
    },
    {
        key: PUPIL_FORM_KEY.emailTutor,
    },
    {
        key: PUPIL_FORM_KEY.telTutor,
    },
/*     {
        key: PUPIL_FORM_KEY.dniTutor,
    }, */
    {
        key: PUPIL_FORM_KEY.referencia,
    },
    {
        key: PUPIL_FORM_KEY.okPrivacy,
    },
]

export default pupilFieldConfig;