import Image001r from '../../../img/work-2022/001r.jpg'
import Image002 from '../../../img/work-2022/002.jpg'
import Image003 from '../../../img/work-2022/003.jpg'
import Image004r from '../../../img/work-2022/004r.jpg'
import Image005 from '../../../img/work-2022/005.jpg'
import Image006 from '../../../img/work-2022/006.jpg'
import Image007 from '../../../img/work-2022/007.jpg'
import Image008 from '../../../img/work-2022/008.jpg'
import Image009 from '../../../img/work-2022/009.jpg'
import Image010 from '../../../img/work-2022/010.jpg'
import Image011 from '../../../img/work-2022/011.jpg'
import Image012r from '../../../img/work-2022/012r.jpg'
import Image013 from '../../../img/work-2022/013.jpg'
import Image014 from '../../../img/work-2022/014.jpg'
import Image015 from '../../../img/work-2022/015.jpg'
import Image016 from '../../../img/work-2022/016.jpg'

const  WorkAssets = [
        {
            image: Image001r,
            title: 'La alegria de encontrarnos.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image002,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image003,
            title: 'Aprender jugando es la mejor forma de aprender.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image004r,
            title: 'Que lindo saliste Alejo!.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image005,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image006,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image007,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image008,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image009,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image010,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image011,
            title: '..',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image012r,
            title: 'Jugar es nuestra forma favorita de aprender.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image013,
            title: '¡Felicitaciones Meli! ¡El esfuerzo valió la pena!.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image014,
            title: 'Un poco de competencia nos desafía y nos alienta a superarnos día a día.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image015,
            title: 'Llevar el mensaje de la inclusión nos permite soñar y volar alto.',
            alt: 'Work',
            objectClass: 'img-wrapper'
        },
        {
            image: Image016,
            title: '',
            alt: 'Work',
            objectClass: 'youtube-pop-up'
        },

    ];

export default WorkAssets;