export interface IFieldValidationErrors {
    key: string,
    field: string,
    type: string,
    value: any,
    test: boolean,
    message: string,
  }
  
  //  It runs basic validation for required fields
  const ValidateFormFields = (
    fields: Array<any>,
    record: Record<string, any>,
  )
  : Array<IFieldValidationErrors> => {
    const requiredFields = fields.filter((field: any) => Boolean(field.required));
    const sectionFields = fields.filter((field: any) => field.type === 'Section');
    sectionFields.forEach((section: any) => section.elements
      .forEach((element: any) => {
        if (element.required === true) {
          requiredFields.push(element);
        }
      }));

    const testField = (field:  Record<string, any>, record: Record<string, any>) => {
      const toTestValue = record.formValues[field.key];
      switch (field.validation.type) {
        case "dni": {
          const dni_regexp =  /^\d{7,8}(?:[-\s]\d{4})?$/;
          const test = dni_regexp.test(toTestValue);
          const message = !test ? 'Ingrese un numero de DNI valido' : '';
          return { 
            test,
            message
          };
        };
        case "email": {
          // eslint-disable-next-line no-empty-character-class
          const email_regexp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
          const test = email_regexp.test(toTestValue);
          const message = !test ? 'Ingrese un email valido' : '';
          return { 
            test,
            message
          };
        };
        case "telephone": {
          // eslint-disable-next-line no-empty-character-class
          const email_regexp = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
          const test = email_regexp.test(toTestValue);
          const message = !test ? 'Ingrese un numero de telefono valido' : '';
          return { 
            test,
            message
          };
        };
        default:
          return { 
            test: !!record.formValues[field.key] && record.formValues[field.key] !== '',
            message: 'Debe completar este campo.'};
      }
    }
  
    return requiredFields.map((field: any) => {
      switch (field.type) {
        case 'TextField':
          return {
            key: field.key,
            field: field.label,
            type: field.type,
            value: record.formValues[field.key],
            test: testField(field, record).test,
            message: testField(field, record).message,
          };
        case 'OptionField':
          return {
            key: field.key,
            field: field.label,
            type: field.type,
            value: record.formValues[field.key],
            test: !!record.formValues[field.key],
            message: 'Debe seleccionar una opcion.',
          };
        case 'DateField':
          return {
            key: field.key,
            field: field.label,
            type: field.type,
            value: record.formValues[field.key],
            test: !!record.formValues[field.key],
            message: 'Debe seleccionar una fecha.',
          };
        case 'CheckField':
          return {
            key: field.key,
            field: field.label,
            type: field.type,
            value: record.formValues[field.key],
            test: !!record.formValues[field.key],
            message: 'Debe aceptar la política de privacidad para poder continuar.',
          };
        default:
          return {
            key: field.key,
            field: field.label,
            value: record.formValues[field.key],
            type: field.type,
            test: !!record.formValues[field.key],
            message: 'Debe completar este campo.',
          }
      }
    }).filter((field: any) => field.test === false);
  };
  
  export const ValidationErrorFields = (errorArray: Array<IFieldValidationErrors>) => errorArray
    .filter((error) => !error.test)
    .map((errorTested) => errorTested.message);
  
  export default ValidateFormFields;
  