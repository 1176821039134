import * as React from 'react';
import { iTeamMember } from 'standardTypes';
import InformationDialog from '../../shared/information-dialog';
import TeamMemberCard from '../../shared/team-member-card';
interface props {
    memberList: Array<iTeamMember>,
    memberType: string,
    noDescription?: boolean
}

const OurTeam: React.FC <props> = ({memberList, memberType, noDescription = false}) => {
    const [teamModalOpen, setTeamModalOpen] = React.useState(false)
    const [selectedMember, setSelectedMember] = React.useState({} as iTeamMember)

    const memberFilteredList = (memberList: Array<iTeamMember>, memberType: string) => 
        memberList.filter((member: iTeamMember) => member.type === memberType)
        .sort((a,b) => a.showOrder - b.showOrder);
    

    const handleOpenProfessionalDetails = (member: iTeamMember) => {
        setTeamModalOpen(true)
        setSelectedMember(member)
    }
    return (
        <section id="team">
            <div className="content-box">
                <div className="content-title wow animated fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
                    <h3>{memberType === 'team' 
                    ? 'Nuestro Equipo'
                    : 'Hacedoras de la Inclusión' 
                    }</h3>
                    <div className="content-title-underline"></div>
                </div>

                <div className="container">
                    <div className="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                        <div className="col-md-12">
                            <div id={memberType === 'team' ? "team-members" : "team-inclusoras"} className="owl-carousel owl-theme">
                                {
                                    memberFilteredList(memberList, memberType).map((teamAsset, idx) =>

                                        <TeamMemberCard
                                            key={idx}
                                            member={teamAsset}
                                            professionalDetails={handleOpenProfessionalDetails}
                                            noDescription = {noDescription}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedMember.memberName && selectedMember.words &&
                <InformationDialog doOpen={teamModalOpen} doClose={() => setTeamModalOpen(false)} title={selectedMember.memberName}>
                    <div className="container-fluid">
                        <div className="col-md-3">
                            <img src={selectedMember.image} className="img-responsive" alt="team member" />
                        </div>
                        <div className="col-md-9">
                            <p className="palabras">{selectedMember.words}</p>
                        </div>
                    </div>
                </InformationDialog>
            }
        </section>
    )
}

export default OurTeam;