import * as React from 'react';
import ServiceProviders from './companies-assets';

const Companies: React.FC = () => {
    return (
        <section id="clients">
            <div className="content-box">
                <div className="container">
                    <div className="row wow animated bounceInLeft" data-wow-duration="1s" data-wow-delay=".5s">
                        <div className="col-md-12">

                            <div id="clients-list" className="owl-carousel owl-theme">

                                {
                                    ServiceProviders.map((item) => (
                                        <div key={item.logo} className="client">
                                            <img src={item.logo} className="img-responsive" alt={item.name} />
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Companies;