import ErrorMessages from "./errors";

interface iRecaptchaResult {
    errorObject?: {
        error?: string
    };
    result: boolean,
    resultToken?: string,  
}

const RecaptchaVerify = async (
    executeRecaptcha: ((action?: string| undefined ) => Promise<string>) | undefined, 
    ): Promise<iRecaptchaResult> => {
    console.log('handleReCaptchaVerify | init')
    if (!executeRecaptcha) {
        console.error('Execute recaptcha not yet available');
        return {
            errorObject: { error: ErrorMessages.errorEnFormulario },
            result: false,
        }
        //setFormErrors({error: ErrorMessages.errorEnFormulario})
        //return false;
    }
    console.log('executeRecaptcha | available')
    let attempts = 0
    let hereToken = undefined
    while (!hereToken || attempts === 3) {
        attempts = +1;
        console.log('Resolving captcha, attempt :', attempts);
        const captchaToken = await executeRecaptcha('yourAction');
        hereToken = captchaToken
    }
    if (attempts === 3 && !hereToken) {
        console.error("|| Failed recaptcha token retrival");
        // setFormErrors({error: ErrorMessages.errorEnFormulario})
        // return false;
        return {
            errorObject: { error: ErrorMessages.errorEnFormulario },
            result: false,
        }
    }
    //setToken(hereToken);
    console.log('NEW Token is: ', hereToken)
    //return hereToken
    return {
        result: true,
        resultToken: hereToken,
    }
}

export default RecaptchaVerify;