import * as React from 'react';

const About = () => (
    <section id="about">

        {/*         <!--About right side with diagonal BG parallax -->
 */}        <div id="about-bg-diagonal" className="bg-parallax"></div>

        {/*         <!-- About left side with content -->
 */}        <div className="container">

            <div className="row">
                <div className="col-md-4">
                    <div id="about-content-box">
                        <div id="about-content-box-outer">

                            <div id="about-content-box-inner">
                                <div className="content-title wow animated fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
                                    <h3> Acerca de Rayces </h3>
                                    <div className="content-title-underline"></div>
                                </div>

                                <div id="about-desc" className="wow animated fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
                                    <p>Vivimos un total <strong>compromiso</strong> por nuestra contribución a la <strong>inclusión</strong> social de las <strong>familias</strong> que necesitan de nuestra ayuda.</p>
                                    <p> Tenemos <strong>pasión</strong> para crear espacios de <strong>confianza</strong>, <strong>amor</strong> y <strong>respeto</strong>, siendo estos espacios verdaderos ejemplos de <strong>integridad</strong> y <strong>alto valor profesional</strong> que se nutren de la <strong>diversidad</strong>.</p>
                                    <p>Ponemos en el centro de la atención a <strong>nuestros niños</strong>, privilegiando la <strong>honestidad</strong>, <strong>confianza</strong> y <strong>creatividad</strong>, logrando de esta manera un solido <strong>equipo</strong> junto a <strong>las familias</strong>, las instituciones y nuestros profesionales.</p>
                                </div>
                                <div id="about-btn" className="wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                                    <a className="btn btn-lg btn-general btn-blue smooth-scroll" href="#work" title="Nuestro trabajo"> Nuestra Pasión </a>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>


    </section>
)

export default About;