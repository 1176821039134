import * as React from 'react';
import Assets from './assets-v2'

const OurWork = () => {

    return (
        <section id="work">
            <div className="content-box">
                <div className="content-title wow animated fadeInDown" data-wow-duration="1s" data-wow-delay=".5s">
                    <h3> Nuestra Pasión </h3>
                    <div className="content-title-underline"></div>
                </div>
                <div className="container-fluid">
                    <div className="row no-gutters wow animated fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                        {
                            Assets.map((asset) => (
                                <div key={asset.image} className="col-md-3 col-sm-4">
                                    <div className={asset.objectClass}>
                                        {
                                            asset.objectClass !== 'youtube-pop-up'
                                                ? (
                                                    <a href={asset.image} title={asset.title}>
                                                        <img src={asset.image} className="img-responsive" alt="Work" />
                                                    </a>
                                                )
                                                : <img src={asset.image} className="img-responsive" alt="Work" />
                                        }

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurWork;