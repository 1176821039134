import React, { useCallback, useEffect, useState } from 'react';
import FormRenderer from '../../form-renderer'
import pupilFieldConfig, { PUPIL_FORM_KEY } from './pupil-field-config';
import PupilFields from './pupil-fields';
import { Box } from '@mui/material';
import signUpRecord, { SignUpRecord } from './signup-record';
import { cloneDeep } from 'lodash';
import validateFormFields, { IFieldValidationErrors, ValidationErrorFields } from '../../../utils/validate-form-fields';
import SubmitSpreadsheetHandler from '../../../utils/api-handlers/submit-spreadsheet-handler';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaVerify from '../../../utils/recaptcha-verify';
import ErrorMessages from '../../../utils/errors';
import { EmailTagType } from '../../mailing/build-mail-tag';

interface iFormError {
    error?: string,
}
interface Props {
    submitForm: boolean;
    cleanSubmitFlag: () => void;
    iAmBusy: (state: boolean) => void;
    handleFinishFlow: (result: []) => void;
    progressCallBack: (progress: number) => void;
}

const NewSignUpForm: React.FC<Props> = ({submitForm, cleanSubmitFlag, iAmBusy, handleFinishFlow, progressCallBack}) => {
    const [record, setRecord] = useState<SignUpRecord>(cloneDeep(signUpRecord))
    const [fieldErrors, setFieldErrors] = useState<IFieldValidationErrors[]>([])
    const [token, setToken] = useState<string | undefined>(undefined);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [formErrors, setFormErrors] = useState({} as iFormError);
    const formFields = pupilFieldConfig.map((config: any) => PupilFields.filter(field => field.key === config.key)[0]);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleChange = (field: Record<string, any>, value: string) => {
        let currentRecord = cloneDeep(record);
        let newRecord;

        // handles changes
        if (value === 'unset') {
            const formValues = currentRecord.formValues;
            const toBUnsetKey = field.key;
            const { [toBUnsetKey]: removedProp, ...updatedFormValues } = formValues as Record<any, string>;
            newRecord = {
                ...record,
                formValues: {
                    ...updatedFormValues,
                }
            };
        } else {
            newRecord = {
                ...record,
                formValues:{
                    ...record.formValues,
                    [field.key]: value,
                }
            };
        }
        // validates and updates error on "onChange" field only
        setFieldErrors(validateFormFields([field], newRecord));
        setRecord(newRecord);
    }

    const handleReCaptchaVerify = useCallback(async () => {
        if (!token) {
            const { errorObject, result, resultToken } = await RecaptchaVerify(executeRecaptcha);
            if (errorObject) { setFormErrors(errorObject) };
            if (token) { setToken(resultToken) };
            return result
        }
        return false

    }, [executeRecaptcha, token]);

    useEffect(()=>{
        const handleReCaptcha = async () => {
            await handleReCaptchaVerify();
        }
        if(submitForm) {
            setFieldErrors(validateFormFields(formFields, record));
            console.log("||FORM READY TO SUBMIT")
            // check for errors before mutation
            console.log('Record up to here ==>', record);
            const formErrors = ValidationErrorFields(validateFormFields(formFields, record));
            setErrors(formErrors);
            if (!!formErrors.length) {
                console.log('Cannot update cloud worksheet. Check form')
            } else {
                const successToken = handleReCaptcha();
                if (successToken) {
                    console.log('Token is: ', successToken)
                    iAmBusy(true);
                    SubmitSpreadsheetHandler({
                        record,
                        formConfig: pupilFieldConfig,
                        formKeys: PUPIL_FORM_KEY,
                        spreadsheetId: '1M111j6UayfIIuZDeJQmt6cia4L-kzkOg9-FSYJWZFxQ',
                        range: `'PREINSCRIPTOS'!A2:E2`,
                        idRange: `'PREINSCRIPTOS'!A:A`,
                        progressCallBack,
                        tutorEmailTagType: EmailTagType.TutorSignup,
                        internalEmailTagType: EmailTagType.InternalSignup,
                    })
                    .then((response) => {
                        iAmBusy(false)
                        setRecord(cloneDeep(signUpRecord)); // Cleans the signup record so they can use the form again.
                        handleFinishFlow(response as []);
                    })
                    .catch((error) => {
                        iAmBusy(false)
                        //The record will net be cleaned, as if the try again in the same session, they can continue with the form already filled.
                        handleFinishFlow(error);
                    })
                } else {
                    console.error('Errors in the form:', formErrors)
                    setFormErrors({ error: ErrorMessages.errorEnFormulario })
                }
            }
        }
        cleanSubmitFlag();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitForm])

    return (
        <Box>
            <FormRenderer
                fields={formFields} 
                record={record} 
                onChange={handleChange} 
                handleClose={() => null}
                errors={fieldErrors}             
            />
        </Box>
    )
}

export default NewSignUpForm;
