
export const RESIGNUP_FORM_KEY = {
    formId: 're-signup-form',
    documentoId: 'rs345',
    periodoLectivo: 'rs228',
    nombreCompletoTutor: 'rs335',
    emailTutor: 'rs220',
    telTutor: 'rs889',
    okPrivacy: 'rs777',
}

const reSignupFieldConfig = [
    {
        key: RESIGNUP_FORM_KEY.documentoId,
    },
    {
        key: RESIGNUP_FORM_KEY.periodoLectivo,
    },
    {
        key: RESIGNUP_FORM_KEY.nombreCompletoTutor,
    },
    {
        key: RESIGNUP_FORM_KEY.emailTutor,
    },
    {
        key: RESIGNUP_FORM_KEY.telTutor,
    },
    {
        key: RESIGNUP_FORM_KEY.okPrivacy,
    },
]

export default reSignupFieldConfig;