import { Box } from '@mui/material';
import * as React from 'react';


const WhatsAppMessenger: React.FC = () => {
    return (
        <Box>
            <a
            href={process.env.REACT_APP_DESKTOP_WHATSAPP_BUSINESS_NUMBER}
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
        </Box>
    )
}

export default WhatsAppMessenger;