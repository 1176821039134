

import * as React from 'react';
import { iTestimonial, iTestimonialObject } from 'standardTypes'


const TestimonialCard: React.FC<iTestimonialObject> = ({ testimonial, testimonialWords }) => {

    const openTestimonialModal = (testimonial: iTestimonial) => {
        if (testimonialWords) {
            testimonialWords(testimonial);
        }
    }

    return (
        <div className="testimonial">
            <img 
                src={testimonial.image} 
                className="img-rsponsive img-circle" 
                alt={testimonial.alt} 
                onClick={() => openTestimonialModal(testimonial)}    
            />
            <blockquote className="text-center">
                <p>{testimonial.quote}</p>
            </blockquote>
            <div className="testimonial-author">
                <p>
                    <strong>{testimonial.testimonialName}</strong>
                    <span>{testimonial.testimonialLocation}</span>
                </p>
            </div>
        </div>
    )
}

export default TestimonialCard;
