import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import TextField from "./fields/text-field";
import ChoiceField, { IValue } from "./fields/option-field";
import DateField from "./fields/date-field";
import CheckBoxField from "./fields/check-box-field";

interface Props {
  fields: Array<any>;
  record: Record<string, any>;
  onChange: <T>(field: any, value: T) => void;
  handleClose: () => void;
  errors: Record<string, any>[];
}

const FormRenderer: Function = ({
  fields,
  onChange,
  record,
  handleClose,
  errors,
}: Props): JSX.Element[] => {
  const [isError, setIsError] = useState(false);
  const myRef = useRef<null | HTMLDivElement>(null);

  const ErrorMessageLabel: React.FC<{ message: string }> = ({ message }) => (
    <Box
      component="p"
      sx={{
        color: "#d32f2f",
        fontWeight: 400,
        fontSize: "1.2857142857142856rem",
        lineHeight: "1.66",
        textAlign: "left",
        marginTop: "0px",
        marginRight: "14px",
        marginBottom: "0",
        marginLeft: "14px",
      }}
    >
      {message}
    </Box>
  );

  const handleError = (error: boolean) => {
    if (error) {
      myRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    return error;
  };

  const handleRef = (field: Record<string, any>, fieldError: any[]) => {
    if (field.required && !!fieldError.length) {
      return myRef;
    }
    return null;
  };

  const renderedField = (field: Record<string, any>, fieldIndex: number) => {
    const fieldError = errors.length
      ? errors.filter((errorField) => errorField.key === field.key)
      : [];
    const errorMessage = fieldError.length ? fieldError[0].message : "";

    switch (field.type) {
      case "TextField":
        return (
          <Box ref={handleRef(field, fieldError)}>
            <TextField
              hidden={field.hidden}
              label={field.label}
              disabled={false}
              required={field.required}
              value={{ textValue: record.formValues[field.key] }}
              onChange={(value) => onChange(field, value.textValue)}
              withError={handleError(!!fieldError.length)}
              helperText={field.description}
              onBlur={() => null}
              onFocus={() => null}
              errorMessage={errorMessage}
            />
          </Box>
        );
      case "DateField":
        return (
          <>
            <DateField
              hidden={field.isHidden}
              label={field.label}
              disabled={false}
              value={{ textValue: record.formValues[field.key] ?? "" }}
              onChange={(value) => onChange(field, value.textValue)}
            />
            <ErrorMessageLabel message={errorMessage} />
          </>
        );
      case "OptionField":
        return (
          <>
            <ChoiceField
              hidden={field.hidden}
              multiple={false}
              label={field.label}
              emptyLabel={record.formValues[field.key]?.empty_label}
              value={{
                selectedValues:
                  record.formValues[field.key]?.choice_values || [],
              }}
              allowOther={false}
              disabled={false}
              onChange={({ selectedValues }: IValue) =>
                onChange(field, { choice_values: selectedValues })
              }
              choices={field.choice_values || []}
            />
            <ErrorMessageLabel message={errorMessage} />
          </>
        );
      case "CheckField":
        return (
          <>
            <CheckBoxField
              hidden={field.isHidden}
              label={field.label}
              disabled={false}
              value={{
                textValue: record.formValues[field.key] === "checked" ?? false,
              }}
              onChange={(value) => onChange(field, value.textValue)}
            >
              {field.description()}
            </CheckBoxField>
            <ErrorMessageLabel message={errorMessage} />
          </>
        );
      default:
        return <></>;
    }
  };

  if (fields) {
    const fieldsToRender = fields.map((field, index) => (
      <Box sx={{ pb: 1 }} data-testid={field.key} key={field.key}>
        {renderedField(field, index)}
      </Box>
    ));
    return fieldsToRender;
  }
  return [<></>];
};
export default FormRenderer;
