/* eslint-disable @typescript-eslint/no-redeclare */
import TutorSignupMessage from "./tutor-signup";
import InternalSignupMessage from "./internal-signup";
import TutorReSignupMessage from "./tutor-re-signup";
import InternalReSignupMessage from "./internal-re-signup";

export const EmailTagType = {
    InternalSignup: 'InternalSignup',
    TutorSignup: 'TutorSignup',
    InternalReSignup: 'InternalReSignup',
    TutorReSignup: 'TutorReSignup',
}

interface EmailFormData {
    to: string,
    subject: string,
    htmlTag: string,
}

const BuildMailTag = (messageType: string, records: string[], reSignupRecord?: string[]): EmailFormData | undefined => {
 /* 
 Method that recieves two props:
    -   Message type: InternalSignup | TutorSignup | InternalReSignup | TutorReSignup
    -   Record Array
    -   It return an EmailDataForm object with all related and preformated email form data.  
 */
    switch (messageType) {
        case EmailTagType.TutorSignup:{
            const emailDataTutor = {
                tutor: records[11],
                to: records[12],
            }
            return {to: emailDataTutor.to, ...TutorSignupMessage(emailDataTutor.tutor)}
        };
        case EmailTagType.TutorReSignup:{
            const emailDataTutor = {
                tutor: records[4],
                emailTutor: reSignupRecord ? reSignupRecord[6] : '',
                nombreAlumno: reSignupRecord ? reSignupRecord[4] : '',
                to: records[5],
            }
            console.log('TutorReSignup | reSignupRecord:', reSignupRecord )
            console.log('TutorReSignup | :', emailDataTutor )
            return {
                to: emailDataTutor.to,
                ...TutorReSignupMessage(
                    emailDataTutor.tutor,
                    emailDataTutor.emailTutor,
                    emailDataTutor.nombreAlumno,
                )
            }
        };
        case EmailTagType.InternalSignup:{
            const inscripcionesGroup = 'julieta.diptorres@rayces.com, maria.eliacavanagh@rayces.com, cecilia.gonzalez@rayces.com, carlos.anriquez@rayces.com';
            //const inscripcionesGroup = 'canriquez@gmail.com, lagartrade@gmail.com, carlos.anriquez@gmail.com'; //<= for testing
            const emailDataInternal = {
                nombre: records[2],
                diagnostico: records[6],
                edad: records[5],
                cobertura: records[9],
                tutor: records[11],
                to: inscripcionesGroup, // This MUST switch to   inscripciones@rayces.com
            }
            return {
                to: emailDataInternal.to,
                ...InternalSignupMessage(
                    emailDataInternal.nombre,
                    emailDataInternal.edad,
                    emailDataInternal.diagnostico,
                    emailDataInternal.cobertura,
                    emailDataInternal.tutor,
                )
            }
        };
        case EmailTagType.InternalReSignup:{
            const inscripcionesGroup = 'julieta.diptorres@rayces.com, maria.eliacavanagh@rayces.com, cecilia.gonzalez@rayces.com, carlos.anriquez@rayces.com';
            //const inscripcionesGroup = 'canriquez@gmail.com, lagartrade@gmail.com, carlos.anriquez@gmail.com'; //<= for testing
            const emailDataTutor = {
                tutor: records[4],
                emailTutor: reSignupRecord ? reSignupRecord[6] : '',
                nombreAlumno: reSignupRecord ? reSignupRecord[4] : '',
                to: inscripcionesGroup,
            }
            console.log('TutorReSignup | reSignupRecord:', reSignupRecord )
            console.log('TutorReSignup | :', emailDataTutor )
            return {
                to: emailDataTutor.to,
                ...InternalReSignupMessage(
                    emailDataTutor.tutor,
                    emailDataTutor.emailTutor,
                    emailDataTutor.nombreAlumno,
                )
            }
        };
        default:
            return undefined;
    }
}

export default BuildMailTag
