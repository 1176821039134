import * as React from 'react';
import Loading from '../../../img/loading-transparent.gif'

const Preinscriptions = () => (
    <div className="wow flipInY mfp-hide neuro-1 preins-form " id="preinscripcion">
        <div className="container-fluid">

            <div className="row">

                {/*                 <!--      <div className="col-lg-8 col-lg-offset-2"> --> */}
                <div className="col-md-12">

                    <h1>RAYCES - Formulario Pre-Inscripciones <br /> Inclusión Escolar SAIE</h1>

                    <div className="mensaje-ok alert alert-success" role="alert" hidden></div>
                    <div className="mensaje-die alert alert-danger" role="alert" hidden></div>

                    <div className="form-slide">
                        {/*                             <!-- This form will be used to slide off after ajax process completed. --> */}

                        <p className="lead">Por favor complete sus datos y nos contactaremos con usted a la brevedad <sup>ver nota 1</sup>.</p>

                        <form method="post" id="prein-form">
                            {/*                                 <!-- this id matches wi the .AJAX call -->
 */}
                            <div className="controls">
                                <div className="basic-data">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="ninonc" type="text" name="nino_nombrec" placeholder="Nombre y Apellido del Niño" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="ninodni" type="text" name="nino_dni" placeholder="DNI" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="ninofn" type="text" name="nino_fnac" placeholder="Fecha de nacimiento dd/mm/aaaa" className="datepicker-here form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="ninod" type="text" name="nino_diag" placeholder="Diagnóstico" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="escolarizado col-md-6">
                                            <select id="esc_option" name="escolar" className="form-control">
                                                <option value="">Está escolarizado?</option>
                                                <option value="SI">SI está escolarizado</option>
                                                <option value="NO">NO está escolarizado</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6" id="instituto_hidden" hidden>
                                            <div className="form-group">
                                                <input id="instit" type="text" name="instit" placeholder="Nombre del Establecimiento." className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="cprov" type="text" name="tutor" placeholder="Nombre y apellido del tutor" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="cemail" type="text" name="email" placeholder="Correo Electronico del tutor" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="ctel" type="text" name="tef" placeholder="Telefono del tutor: (Cod)-(15)-XXXXXXXX.." className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pre-opciones">
                                    <div className="opciones-cobertura" id="pago">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="cobselector col-md-6 form-pago">
                                                    <select id="cob_selector" name="cobertura" className="form-control">
                                                        <option value="">Seleccione Cobertura de Salud</option>
                                                        <option value="Aca Salud">Aca Salud</option>
                                                        <option value="Ase Nacional">Ase Nacional</option>
                                                        <option value="IOSFA">IOSFA</option>
                                                        <option value="Luis Pasteur">Luis Pasteur</option>
                                                        <option value="Medife">Medifé</option>
                                                        <option value="OSDE">OSDE</option>
                                                        <option value="OSPE">Ospe</option>
                                                        <option value="OSPECOM">Ospecom</option>
                                                        <option value="Poder Judicial">Poder Judicial</option>
                                                        <option value="SANCOR">Sancor Salud</option>
                                                        <option value="Swiss Medical">Swiss Medical</option>
                                                        {/*                                                             <!-- <option value="Boreal">Boreal</option> -->
                                                            <!-- <option value="DIBA">DIBA</option> -->
                                                            <!-- <option value="IOSE">IOSE</option> -->
                                                            <!-- <option value="Luz y Fuerza">Luz y Fuerza</option> -->
                                                            <!-- <option value="OSCEP">OSCEP</option> -->
                                                            <!-- <option value="OSDE">OSDE</option> -->
                                                            <!-- <option value="OSSIMRA">OSSIMRA</option> --> */}
                                                        <option value="Otro">Otra Obra Social</option>
                                                        <option value="Particular">Particular</option>
                                                    </select>
                                                </div>
                                                <div className="cudselector col-md-6">
                                                    <select id="cud_selector" name="cud" className="form-control">
                                                        <option value="">Tiene Certificado Único de Discapacidad (CUD)?</option>
                                                        <option value="SI">SI tiene CUD</option>
                                                        <option value="NO">NO tiene CUD</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input id="cemail" type="text" name="derivacion" placeholder="Cómo llegó a Rayces?" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="priva form-group" id="check-form">
                                                    <input type="checkbox" name="privacy" value="okprivacy" /> He leído y acepto las condiciones de <a href="/policy/privacidad.html" target="_blank">Política de Privacidad</a> de rayces.com
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="g-recaptcha" data-sitekey="6LcYmSQUAAAAAKJMI1-PJBR-Us2NSQSSgEW8bYbV" data-callback="recaptchaCallback"></div>
                                                <input type="hidden" className="hiddenRecaptcha required" name="hiddenRecaptcha" id="hiddenRecaptcha" />
                                            </div>
                                        </div>
                                        <div className="event-form-envio">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="submit" id="buttonOne" className="btn btn-lg btn-general btn-blue smooth-scroll btn-event-form preins" value="Enviar preinscripción" />
                                                </div>
                                                <div className="col-md-6 loading" hidden id="loadingf">
                                                    <img src={Loading} className="img-responsive" alt="loading" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="event-form-notas">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="text-muted"><strong>Nota 1:</strong> Somos equipo de apoyo a la inclusión.</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                {/*                     <!-- /.8 --> */}

            </div>
            {/*                 <!-- /.row--> */}

        </div>
        {/*             <!-- /.container--> */}
    </div>
)

export default Preinscriptions;