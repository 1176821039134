/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { TextField } from '@mui/material/';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/es';
import { MobileDatePicker } from '@mui/x-date-pickers';

export type InputValue = { textValue: string }

type PickerValue = Moment | null;
type KeyboardValue = string | undefined;
export type DatePickerValue = PickerValue | KeyboardValue;

interface Props  {
  label: string,
  disabled: boolean,
  hidden?: boolean,
  value: { textValue: string },
  onChange: (date: InputValue) => void
}

const formatSystemDate = (date: DatePickerValue) => moment(date).format('DD-MM-YYYY');

const DateField: React.FC<Props> = ({ hidden, label, disabled, value, onChange }) => {
  const [selecteValue, setSelectedValue] = useState<DatePickerValue>(moment(value.textValue, 'DD-MM-YYY'));
  const format = { textValue: '' };

  useEffect(() => { setSelectedValue(value.textValue); }, [value]);

  const handleChange = (date: DatePickerValue) => {
    if ((date as Moment)?.isValid()) {
    
      setSelectedValue(date);
      format.textValue = date as string;
    } else {
      format.textValue = '';
    }

    onChange(format);
  };

  return (
    <>
      {!hidden && (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <MobileDatePicker
            label={label}
            disabled={disabled}
            value={selecteValue}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={false}
              />
            )}
          />
        </LocalizationProvider>
      )}
    </>
  );
};

export default DateField;
