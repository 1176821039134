import * as React from 'react';

const HomeSection = () => (
/*     < !--Home -- > */
    <section id="home">
        <div id="home-cover" className="bg-parallax animated fadeIn">

            <div id="home-content-box">

                <div id="home-content-box-inner" className="text-center">

                    <div id="home-heading" className="animated zoomIn">

                        <h3>RAYCES
                        </h3>
                        <div id="home-subtitle" className="animated zoomIn">
                            <h3>Centro de Rehabilitacion e Inclusión Escolar</h3>
                        </div>

                    </div>
                    <div id="home-btn" className="animated zoomIn">

                        <a className="btn btn-lg btn-general btn-white smooth-scroll" href="#about" role="button" title="View Our Work">Quienes Somos</a>

                    </div>

                </div>

            </div>
        </div>


    </section>
)

export default HomeSection;