import * as React from 'react';
import Loading from '../../../img/loading-transparent.gif'

const Reinscriptions = () => (
    /*     < !--Reinscripciones - Alumno Activo Rayces --> */

    <div className="wow flipInY mfp-hide neuro-1 preins-form " id="pre_existente">
        <div className="container-fluid">

            <div className="row">

                {/*                 <!--      <div className="col-lg-8 col-lg-offset-2"> --> */}
                <div className="col-md-12">

                    <h1>RAYCES - FORMULARIO DE REINSCRIPCION<br /> para Alumnos del Ciclo Actual</h1>

                    <div className="mensaje-ok alert alert-success" role="alert" hidden></div>
                    <div className="mensaje-die alert alert-danger" role="alert" hidden></div>
                    <div className="mensaje-warning alert alert-warning" role="alert" hidden></div>

                    <div className="form-slide">
                        {/*                             <!-- This form will be used to slide off after ajax process completed. -->
 */}                            <p className="lead">Por favor complete sus datos y nos contactaremos con usted a la brevedad <sup>ver nota 1</sup>.</p>
                        <form method="post" id="pre_activo_form">
                            {/*                                 <!-- this id matches wi the .AJAX call -->
 */}
                            <div className="controls">
                                <div className="basic-data">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input id="ninodni" type="text" name="nino_dni" placeholder="DNI del alumn@ a confirmar" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="ctutor" type="text" name="tutor" placeholder="Nombre y apellido del tutor que confirma" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id="cemail" type="text" name="email" placeholder="Correo Electronico del tutor" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pre-opciones">
                                    <div className="opciones-cobertura" id="pago3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="g-recaptcha" data-sitekey="6LcYmSQUAAAAAKJMI1-PJBR-Us2NSQSSgEW8bYbV" data-callback="recaptchaCallback"></div>
                                                <input type="hidden" className="hiddenRecaptcha2 required" name="hiddenRecaptcha2" id="hiddenRecaptcha2" />
                                            </div>
                                        </div>
                                        <div className="event-form-envio">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="submit" id="buttonExistentOne" className="btn btn-lg btn-general btn-blue smooth-scroll btn-event-form preins" value="Enviar reinscripción" />
                                                </div>
                                                <div className="col-md-6 loading" hidden id="loading_existing">
                                                    <img src={Loading} className="img-responsive" alt="loading" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="event-form-notas">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="text-muted"><strong>Nota 1:</strong> Somos equipo de apoyo a la inclusión.</p>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                {/*                     <!-- /.8 --> */}

            </div>
            {/*                 <!-- /.row--> */}

        </div>
        {/*             <!-- /.container--> */}
    </div>
)

export default Reinscriptions;