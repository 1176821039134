export interface SignUpRecord {
    formId: string,
    formValues: Record<string, any>,
}

const signUpRecord = {
    formId: 'pupil-signup-form',
    formValues: {
    }
}
//FOr testing only //
/* const signUpRecord = {
    "formId": "pupil-signup-form",
    "formValues": {
        "a234": "Progress II",
        "b345": "22828983",
        "c567": "2022-09-07T03:00:00.000Z",
        "d789": "TEA",
        "e123": {
            "choice_values": [
                "si"
            ]
        },
        "f789": "Sto",
        "x889": {
            "choice_values": [
                "OSPE"
            ]
        },
        "c098": {
            "choice_values": [
                "si"
            ]
        },
        "t335": "Tiiti",
        "m220": "canriquez@gmail.com",
        "t889": "01122888829",
        "r898": "Sooooc",
        "p777": "checked"
    }
} */

export default signUpRecord;